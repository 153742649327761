<template>
    <v-container style="background:#f2f2f2; min-height:100vh;">
        <v-row class="py-0" style="background:white;">
            <v-col cols="3">
                <v-btn :to="{ path: '/'}" text style="text-transform: capitalize;">
                    <v-icon> mdi-chevron-left</v-icon> Inicio
                </v-btn>
            </v-col>
            <v-col cols="9" class="py-4" style="text-align:center; font-size:18px;">
                Resumen de tu Pedido
            </v-col>
        </v-row>

        <div class="mt-8" style="text-align:center; font-size:23px;">
          <v-img style="max-width:150px!important; margin:auto;" class="mb-2" src="https://tacoselguero.mx/wp-content/uploads/2021/01/Captura-removebg-preview.png"></v-img>
          Gracias por tu compra!
        </div>
        <div style="text-align:center;">Estos son los productos de tu pedido:</div>
        
        <div style="filter:opacity(0.21); text-align:center;" v-if="pedido==undefined">
            <v-img max-width="50vw" style="margin:25vw 25vw 30px 25vw;" src="https://tacoselguero.mx/wp-content/uploads/2021/05/shopping-cart-empty-side-view-1.png"></v-img>
            <strong style="display: table; font-size: 25px; margin: auto;">Tu pedido no se pudo <br>realizar con exito</strong><br>
            Intentalo de nuevo o <br>llámanos a tu sucursal favorita
        </div>

        <template v-else v-for="(carrito,index) in pedido.platos">
            <v-card flat :key="index">
                <div style="text-align:left; background-color:#f2f2f2!important;">
                    <v-row class="ma-5 pa-1" style="background:white; border-radius:4px;">
                        <v-col class="my-1" cols="4">
                            <v-img style="height:60px!important;" :src="foto(carrito.producto)"></v-img>
                        </v-col>
                        <v-col cols="8" class="py-6">
                            <strong>({{carrito.cantidad}})</strong>
                            <span class="mx-2">{{producto(carrito.producto)}}</span>
                            <br>
                            <strong>{{carrito.total.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}</strong>
                            
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </template>

        <div style="text-align:center;">Status de tu pedido: 
          <v-icon x-small v-if="pedido.status=='en curso'" color="#2e2a8e"> mdi-checkbox-blank-circle</v-icon>
          <v-icon x-small v-if="pedido.status=='confirmado'" color="primary"> mdi-checkbox-blank-circle</v-icon>
          <v-icon x-small v-if="pedido.status=='enviado'" color="success"> mdi-checkbox-blank-circle</v-icon>
          <v-icon x-small v-if="pedido.status=='cancelado'" color="red"> mdi-checkbox-blank-circle</v-icon>
          
          {{pedido.status}}</div>


        <div style="text-align:right;" class="mx-6 mt-6">
          <strong>Total: {{pedido.total.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}</strong>
        </div>

        <div style="text-align:left; background-color:white;" class="mx-1 mt-6 pa-6">
          <strong>Dudas y/o aclaraciones:</strong>
          <div style="height:10px;"/>
          <v-icon small class="mr-2"> mdi-phone</v-icon> <span>Telefono: {{sucursal(pedido.sucursal).telefonos}}</span>
          <br>
          <v-icon small class="mr-2"> mdi-map-marker</v-icon> <span>Dirección: {{sucursal(pedido.sucursal).direccion}}</span>
          <br>
          <v-icon small class="mr-2"> mdi-email</v-icon> <span>Email: <a>contacto@tacoselguero.mx</a></span>
          
        </div>
    </v-container>
</template>


<script>
  export default {
    computed:{
        pedido(){
            return this.$store.state.pedido.pedidos.filter(sucursal=>sucursal.id == localStorage.getItem("pedido"))[0]
        }
    },
    created(){
        this.$store.dispatch('pedido/getPedidos')
        this.$store.dispatch('producto/getProductos')
        this.$store.dispatch('sucursal/getSucursales')
    },
    methods:{
      producto(id){
        return this.$store.state.producto.productos.filter(producto=>producto.id == id).map(producto=>producto.nombre)[0]
      },
      foto(id){
        return this.$store.state.producto.productos.filter(producto=>producto.id == id).map(producto=>producto.foto)[0]
      },
      sucursal(id){
        return this.$store.state.sucursal.sucursales.filter(sucursal=>sucursal.id == id)[0]
      }
    },
    mounted(){
      Echo.channel('test')
          .listen('Test', (e) => {
            this.$store.dispatch('pedido/getPedidos')
          })
    },
  }
</script>